<template>
  <div>
    <div class="flex items-center justify-between mb-2">
      <h2>{{$t('Stock Requests')}}</h2>
      <vs-alert class="w-1/2" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
        {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
      </vs-alert>
      <vs-button v-else @click="openStockModal('New Stock Request')" color="primary" icon-pack="feather" icon="icon-plus" size="large"></vs-button>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadStockRequests"></head-component>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="stockRequests"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Request ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created')}}</shipblu-th>
        <shipblu-th>{{$t('Number of SKUs')}}</shipblu-th>
        <shipblu-th>{{$t('Total Quantity')}}</shipblu-th>
        <shipblu-th>{{$t('Number Of Vehicles')}}</shipblu-th>
        <shipblu-th>{{$t('Received')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th class="step-12 order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-stock-request-view`, params: { type: 'stock-requests', stockID: data[indextr].id}}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].requestID">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p v-on:click.stop class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].id }}
                <span @click="copied(data[indextr].id)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].createdOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">{{ common.formatDate(data[indextr].created, $i18n.locale) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ data[indextr].created ? ($i18n.locale === 'en' ? common.gettingDate(data[indextr].created).split(',')[0] : common.gettingDate(data[indextr].created).split('،')[0]) : '' }}</p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].numberOfSKU">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Number of SKUs')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].number_of_skus }}</p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].quantity">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Total Quantity')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].total_quantity }}</p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].vehicles">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Number Of Vehicles')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].num_vehicles }}</p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].receivedOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Received')}}</p>
            <p class="sm:text-base text-sm">{{ common.formatDate(data[indextr].received_date, $i18n.locale) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ data[indextr].received_date ? ($i18n.locale === 'en' ? common.gettingDate(data[indextr].received_date).split(',')[0] : common.gettingDate(data[indextr].received_date).split('،')[0]) : '' }}</p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td v-if="(!$route.params.merchantID && !$route.params.warehouseID) || $store.state.AppActiveUser.userRole === 'customer-support'" class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item v-if="data[indextr].status === 'draft' || data[indextr].status === 'submitted'"
                    @click="updateStockRequestStatus(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="XCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Cancel Stock Request')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item v-if="data[indextr].status === 'draft'"
                    @click="readyToSend(data[indextr].id)">
                    <span class="flex items-center">
                      <feather-icon
                        icon="SendIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{ $t('Ready to Send')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <add-stock-request :stockModal="stockModal" @stockModal="stockModal = $event" @loadStockRequests="loadStockRequests" :type="type" :stockRequest="stockRequest"></add-stock-request>
  </div>
</template>

<script>
import AddStockRequest from './components/AddStockRequest.vue'
import common  from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import HeadComponent from '../components/Header.vue'
import i18nData from '../../../i18n/i18nData'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all',
          color: ''
        },
        {
          name: 'draft',
          value: 'draft'
        },
        {
          name: 'submitted',
          value: 'submitted'
        },
        {
          name: 'receiving in progress',
          value: 'ready,received,inbounded'
        },
        {
          name: 'fully received',
          value: 'stored'
        },
        {
          name: 'partially received',
          value: 'partially_received'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        }
      ],
      stockModal: false,
      type: '',
      stockRequests: [],
      stockRequest: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      filters: [],
      cancelledData: {},
      tableLoader: false,
      merchantStatus: ''
    }
  },
  components: {
    AddStockRequest,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    HeadComponent,
    ShipbluPagination
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRequests()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadStockRequests()
      }
    }
  },
  methods: {
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStockRequests()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRequests()
    },
    getStyle (data) {
      if (data.status === 'ready' || data.status === 'received' || data.status === 'inbounded') {
        return common.getOrderStatusColor('receiving_in_progress')
      } else {
        return common.getOrderStatusColor(data.status)
      }
    },
    getOrderStatusColor (stock) {
      return common.getOrderStatusColor(this.getStatusLabel(stock))
    },
    getStatusLabel (stock) {
      return common.getStatusLabel(stock, this.$store.state.AppActiveUser.userRole)
    },
    openStockModal (type) {
      this.type = type
      if (type === 'New Stock Request') {
        this.stockRequest = Object.assign({}, {})
      } 
      this.stockModal = true
    },
    loadStockRequests () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&status=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${query}`, 'get', null, true,
        (response) => {
          this.stockRequests = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStockRequestsSearch)
    },
    loadStockRequestsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStockRequests()
    },
    updateStockRequestStatus (data) {
      this.cancelledData = data
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure you want to cancel this stock request?'],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: this.confirmUpdate
      })
    },
    confirmUpdate () {
      let trackings = []
      trackings = {
        order: this.cancelledData.id,
        status: 'cancelled'
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.cancelledData.id}/tracking/`, 'post', trackings, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock Request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadStockRequests()
        }
      )
    },
    readyToSend (id) {
      const tracking = {
        stock_request: id,
        status: 'submitted'
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${id}/tracking/`, 'post', tracking, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadStockRequests()
        }
      )
    }
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantStatus = user.status
    })
    this.loadStockRequests()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
table.shipblu-table td:first-child{
  border-left: 0px solid !important;
}
</style>